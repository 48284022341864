import {useQuery, useMutation} from 'graphql/client';
import ClientOrganizationCachedSignals from 'graphql/pages/signals/queries/ClientOrganizationCachedSignals.graphql';
import GenerateCachedSignalQuery from 'graphql/pages/signals/mutations/GenerateCachedSignal.graphql';
import {
    ClientOrganizationCachedSignalsPayload,
    QueryClientOrganizationCachedSignalsArgs,
    CachedSignal,
    GenerateCachedSignalPayload,
    MutationGenerateCachedSignalArgs,
} from 'src/types/graphql-types';
import {Dispatch, SetStateAction, useEffect} from 'react';
import {useSessionStorage} from 'components/hooks/useSessionStorage';

export const MAXIMUM_SIGNALS = 6;

function useClientOrganizationCachedSignalsQuery(
    clientOrganizationId: string
): {
    signals: CachedSignal[];
    loading: boolean;
    sessionCachedSignal: CachedSignal;
    setSessionCachedSignal: Dispatch<SetStateAction<CachedSignal>>;
    clearSessionCachedSignal: () => void;
} {
    const {data, loading} = useQuery<
        {
            clientOrganizationCachedSignals: ClientOrganizationCachedSignalsPayload;
        },
        QueryClientOrganizationCachedSignalsArgs
    >(ClientOrganizationCachedSignals, {
        variables: {
            clientOrganizationId,
        },
        fetchPolicy: 'network-only',
    });

    const [
        generateCachedSignal,
        {loading: generateCachedSignalLoading, data: generateCachedSignalData},
    ] = useMutation<
        {generateCachedSignal: GenerateCachedSignalPayload},
        MutationGenerateCachedSignalArgs
    >(GenerateCachedSignalQuery, {
        variables: {
            clientOrganizationId,
        },
        refetchQueries: [ClientOrganizationCachedSignals],
    });

    useEffect(() => {
        if (
            data?.clientOrganizationCachedSignals?.cachedSignals?.length <
            MAXIMUM_SIGNALS
        ) {
            generateCachedSignal().catch(() => null);
        }
    }, [data, generateCachedSignal, generateCachedSignalData]);

    const cachedSignals =
        data?.clientOrganizationCachedSignals?.cachedSignals ?? [];

    const {
        sessionValue: sessionCachedSignal,
        setSessionValue: setSessionCachedSignal,
    } = useSessionStorage('cachedSignal', null);

    return {
        signals: cachedSignals,
        loading: loading || generateCachedSignalLoading,
        sessionCachedSignal,
        setSessionCachedSignal,
        clearSessionCachedSignal: () => setSessionCachedSignal(null),
    };
}

export {useClientOrganizationCachedSignalsQuery};
